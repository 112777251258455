<template>
  <div data-app>
    <template>
      <v-data-table
          :headers="headers"
          :items="usersEx"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :server-items-length="total"
          @update:items-per-page="updateItemPerPage"
          @update:page="updatePage"
          :footer-props="{ itemsPerPageOptions: [5, 10, 15, 20] }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách Gia sư
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="add()">Thêm mới</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.created_at="{ item }">
          <span >{{ formatDateTime(item.created_at) }}</span>
        </template>
        <template v-slot:item.authority="{ item }">
          <span>Gia sư</span>
        </template>

        <template v-slot:item.actions="{ item }" >
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)" >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search found no results.
          </v-alert>
        </template>
      </v-data-table>
    </template>
  </div>
</template>
<script>
import {mapGetters, mapState} from "vuex";
import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {GET_LIST_USERS} from "@/store/user.module";
import {validationMixin} from "vuelidate";
// import HnrInput from '@/components/elements/hnr-input'
import {SET_LOADING} from "@/store/loading.module";
import {DELETE_USER} from "@/store/user.module";
import {ERROR} from "@/store/alert.module";
import JwtService from "@/common/jwt.service";
import {DELETE_USER_EX, GET_LIST_USER_EX} from "@/store/authority.module";
export default {
  mixins: [validationMixin],
  components: {
    // HnrInput
  },
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id"
      },
      {text: "Full name", value: "full_name"},
      {text: "Email", value: "email"},
      {text: "Quyền truy cập", value: "authority"},
      {text: "Ngày tạo", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false}
    ],
  }),

  computed: {
    ...mapGetters({
      usersEx: "usersEx",
      total: "totalUser",
    }),
  },
  created() {
    this.authority = JwtService.getAuthority() || 1;
    this.getUsers();
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Gia sư", route: "/user-tutor"}]);
  },
  methods: {
    add() {
      this.$router.push({name: 'user-tutor-add'});
    },
    editItem(item) {
      this.$router.push({name: 'user-tutor-edit', params: {id: item.id}});
    },
    getUsers() {
      let payload = {
        page: this.page,
        per_page: this.itemsPerPage,
        authority:3
      }
      this.$store.dispatch(SET_LOADING, true);
      this.$store.dispatch(GET_LIST_USER_EX, payload).then(() => {
        this.$store.dispatch(SET_LOADING, false);
      }).catch(() => {
        this.$store.dispatch(SET_LOADING, false);
      });
      ;
    },
    updateItemPerPage($event) {
      this.itemsPerPage = $event;
      this.getUsers();
    },

    updatePage($event) {
      this.page = $event;
      this.getUsers();
    },

    deleteItem(item) {
      const index = this.usersEx.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
      this.$store.dispatch(DELETE_USER_EX, item.id).then((data) => {
        if (data.status) {
          this.usersEx.splice(index, 1);
        } else {
          if (data.errorCode == 424) {
            this.$store.dispatch(ERROR, data.message, {root: true});
          } else {
            this.$store.dispatch(ERROR, "Xóa thất bại!", {
              root: true,
            });
          }
        }
      });
    },
    formatDateTime(date) {
      if (!date) return null
      const newDate = new Date(date)
      const [year, month, day, hour, minute, millisecond] = [newDate.getFullYear(), newDate.getMonth() + 1, newDate.getDate(),
        newDate.getHours(), newDate.getMinutes(), newDate.getMilliseconds()]
      const hour_format = hour > 9 ? hour : `0${hour}`
      const minute_format = minute > 9 ? minute : `0${minute}`
      const millisecond_format = millisecond > 9 ? millisecond : `0${millisecond}`
      return `${hour_format}:${minute_format}:${millisecond_format} ${day}/${month}/${year}`
    },
  }
};
</script>
